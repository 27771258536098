.sticky-banner {
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #F7CACD;
  color: #000;
  z-index: 999999;
  padding-left: 8px;
  padding-right: 30px;
}
.sticky-banner.loading {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
@media (min-width: 768px) {
.sticky-banner {
    padding-left: 30px;
    padding-right: 40px;
}
}
.sticky-banner .wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  position: relative;
  z-index: 1;
}
@media (min-width: 992px) {
.sticky-banner .wrap {
    padding-right: 160px;
}
}
.sticky-banner .cloud {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: auto;
  right: 0;
}
.sticky-banner .logo-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sticky-banner .logo-left .logo {
  margin-bottom: 6px;
}
@media (min-width: 768px) {
.sticky-banner .logo-left .link {
    display: none;
}
.sticky-banner .logo-left .logo {
    margin-bottom: 0;
}
}
.sticky-banner .logo-right {
  display: none;
}
@media (min-width: 768px) {
.sticky-banner .logo-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 20px;
}
}
@media (min-width: 1200px) {
.sticky-banner .logo-right {
    margin-top: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 10px;
}
}
.sticky-banner .testo {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 13px;
  padding: 14px;
  line-height: 120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.sticky-banner .testo .link {
  display: none;
}
@media (min-width: 768px) {
.sticky-banner .testo {
    font-size: 18px;
    padding: 14px;
}
.sticky-banner .testo .link {
    display: inline;
    margin-left: 30px;
}
}
.sticky-banner .link {
  font-size: 12px;
  color: #fff;
  background: #000;
  padding: 6px 15px;
  border-radius: 6px;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  cursor: pointer;
}
.sticky-banner .link:hover {
  background: #343a40;
  color: #fff;
}
@media (min-width: 768px) {
.sticky-banner .link {
    padding: 9px 35px;
    font-size: 20px;
    border-radius: 10px;
}
}
.sticky-banner .toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 10px;
  z-index: 1;
}
.sticky-banner .toggle svg {
  cursor: pointer;
}
@media (min-width: 768px) {
.sticky-banner .toggle svg {
    margin-left: 1rem;
}
}
.sticky-banner .cloud {
  display: none;
}
@media (min-width: 768px) {
.sticky-banner .cloud {
    display: inline;
}
}
.sticky-banner .testo-small {
  font-size: 11px;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
  text-align: center;
  padding-bottom: 10px;
  font-weight: 600;
}
.sticky-banner .testo-small span {
  display: block;
}
@media (min-width: 768px) {
.sticky-banner .testo-small span {
    display: inline-block;
    padding: 0 8px;
}
}
@media (min-width: 1200px) {
.sticky-banner .testo-small {
    -webkit-box-flex: initial;
        -ms-flex: initial;
            flex: initial;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-bottom: 0;
}
.sticky-banner .testo-small span {
    display: inline-block;
    padding: 0 8px;
}
}